import React from 'react';
import {getOutlet} from 'reconnect.js';
import {Button, Popconfirm} from 'antd';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../AppActions';

export function handleProducts({path, pageContext}) {
  const user = getOutlet('user').getValue();

  if (path === '/admin/products') {
    const cats = user.store_configs.product_category?.categories;

    if (
      pageContext.resource.formSpec.schema?.properties?.labels?.items &&
      Array.isArray(cats)
    ) {
      pageContext.resource.formSpec.schema.properties.labels.items = {
        type: 'string',
        enum: cats.map((cat) => cat.name),
        enumNames: cats.map((cat) => cat.display),
      };
    }

    pageContext.resource.renderDeleteButton = (record, clientCallbacks) => {
      const {refresh} = clientCallbacks;
      return (
        <Popconfirm
          cancelText={'取消'}
          okText={'確定'}
          title={
            <p>
              確定要刪除此商品？
              <br />
              注意：此動作無法復原
            </p>
          }
          placement="topRight"
          onConfirm={async () => {
            try {
              AppActions.setLoading(true);

              const pk = pageContext.resource.primaryKey;
              await JStorage.deleteDocument('product', {id: record[pk]});
              // await JStorage.cacheDocuments(
              //   'product',
              //   {
              //     store_id: user.store_id,
              //   },
              //   null,
              //   null,
              //   null,
              //   undefined,
              //   {
              //     key: `product-cache-${user.store_id}.json`,
              //   },
              // );
              await refresh();
            } catch (ex) {
              console.warn(ex);
            } finally {
              AppActions.setLoading(false);
            }
          }}>
          <Button danger className="resource-delete-button">
            刪除
          </Button>
        </Popconfirm>
      );
    };
  }
}
