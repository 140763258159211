import React from 'react';
import {Helmet} from 'react-helmet';
import AdminResource from 'rev.sdk.js/Generators/AdminResource';
import {Tag} from 'antd';
import {useOutlet, getOutlet} from 'reconnect.js';
import {ArticleEditor} from 'rev.sdk.js';
import * as Cart from 'rev.sdk.js/Actions/Cart';
import * as Constants from '../../constants';
import * as AdminOrderWidget from './AdminOrderWidget';
import AdminOrderDetailForm from './AdminOrderDetailForm';
import LineIntegrationForm from './AdminLineIntegrationForm';
import AdminLinkButton from './AdminLinkButton';
import AdminProductStockSummary from './AdminProductStockSummary';
import {handleOrders} from './handleOrders';
import {handleBilling} from './handleBilling';
import {handleProducts} from './handleProducts';
import {handleOrdersExport} from './handleOrdersExport';

function AdminResourcePage(props) {
  const {path, pageContext} = props;
  const [user] = useOutlet('user');

  React.useEffect(() => {
    const checkWizardBillingAchieve = async () => {
      let wizard = getOutlet('setup-wizard').getValue();
      if (
        wizard.steps[wizard.curStepIdx]?.key ===
          Constants.WIZARD_STEP_KEY.CHECK_BILLING &&
        !wizard.steps[wizard.curStepIdx]?.achieve
      ) {
        window.localStorage.setItem(
          `${user.store_id}_wizard_${Constants.WIZARD_STEP_KEY.CHECK_BILLING}`,
          'true',
        );
        await wizard.checkAchieve(Constants.WIZARD_STEP_KEY.CHECK_BILLING);
      }
    };
    if (path === '/admin/billing') {
      checkWizardBillingAchieve();
    }
  }, [path, user.store_id]);

  function renderCustomAdminSection(props) {
    const {name, type, context} = props;

    if (
      type === 'form' &&
      name === 'AdminOrderDetailForm' &&
      context.position === 'bottom'
    ) {
      return <AdminOrderDetailForm context={context} />;
    } else if (
      type === 'form' &&
      name === 'ArticleForm' &&
      context.position === 'top'
    ) {
      return (
        <ArticleEditor
          collection={{name: 'Article_Default'}} // will be removed in sdk
          onUpdate={() => 0} // will be removed in sdk
          onClose={() => 0} // will be removed in sdk
          document={context?.instance}
        />
      );
    } else if (
      type === 'form' &&
      name === 'LineIntegrationForm' &&
      context.position === 'top'
    ) {
      return <LineIntegrationForm />;
    } else if (
      type === 'resource' &&
      path.indexOf('/admin/orders') !== -1 &&
      context.position === 'middle'
    ) {
      return <AdminOrderWidget.OrderExtraQueries {...context} />;
    }

    return null;
  }

  function renderCustomAdminCol(props) {
    const {col, record} = props;
    if (col.customType === 'labels') {
      return (record.labels || []).map((l, idx) => <Tag key={idx}>{l}</Tag>);
    } else if (col.customType === 'site-config-name') {
      return (
        <AdminLinkButton
          title={Constants.SITE_CONFIG[record.name]?.display || record.name}
          url={`/admin/${record.name}?action=detail&id=${record.id}`}
        />
      );
    } else if (col.customType === 'order_status') {
      return <AdminOrderWidget.OrderStatusCustomElem record={record} />;
    } else if (col.customType === 'order_ui_status') {
      return <AdminOrderWidget.OrderUiStatusCustomElem record={record} />;
    } else if (col.customType === 'payment_status') {
      return <AdminOrderWidget.PaymentStatusCustomElem record={record} />;
    } else if (col.customType === 'logistics_status') {
      return <AdminOrderWidget.LogisticsStatusCustomElem record={record} />;
    } else if (col.customType === 'logistics_type') {
      let value = Cart.LOGISTICS_TYPE_DISPLAY[record.logistics_type]?.label;
      return (
        <span style={value ? {color: '#CBCBCB'} : {}}>{value || '-- --'}</span>
      );
    } else if (col.customType === 'offline_tx') {
      return (
        <div style={{fontSize: 18, fontWeight: 'bold', letterSpacing: 2}}>
          {record.offline_tx}
        </div>
      );
    } else if (col.customType === 'billing_status') {
      const COLOR_MAP = {
        [Constants.BILLING_STATUS.success]: '#9FD000',
        [Constants.BILLING_STATUS.pending]: '#CBCBCB',
        [Constants.BILLING_STATUS.submitted]: '#c1b19b',
        [Constants.BILLING_STATUS.rejected]: '#FF404C',
      };
      return (
        <Tag color={COLOR_MAP[record.status] || COLOR_MAP.pending}>
          {Constants.BILLING_STATUS_DISPLAY[record.status]}
        </Tag>
      );
    } else if (col.customType === 'stock_detail_btn') {
      return (
        <AdminLinkButton
          title={record.name}
          url={`/admin/stocks/?action=detail&id=${record.id}`}
        />
      );
    } else if (col.customType === 'stock_type') {
      return <AdminProductStockSummary record={record} />;
    } else if (col.customType === 'stock_amount') {
      if (!record.stock_type || record.stock_type === 'always') {
        return '---';
      }
      return record.stock_amount;
    } else if (col.customType === 'stock_sold_amount') {
      if (!record.stock_type || record.stock_type === 'always') {
        return '---';
      }
      return record.stock_sold_amount;
    }

    return null;
  }

  // render create button overwrite
  if (
    pageContext.resource.renderCreateButton ||
    pageContext.resource.renderCreateButton === undefined
  ) {
    pageContext.resource.renderCreateButton = ({clientCallbacks}) => {
      return (
        <AdminLinkButton
          title={`新增${pageContext.resource.name || ''}`}
          url={`${pageContext.resource.path}?action=create`}
        />
      );
    };
  }

  handleProducts({path, pageContext});
  handleOrders({path, pageContext});
  handleOrdersExport({path, pageContext});
  handleBilling({path, pageContext});

  return (
    <>
      <Helmet>
        <script src="https://cdn.sheetjs.com/xlsx-latest/package/dist/xlsx.full.min.js"></script>
      </Helmet>

      <AdminResource
        renderCustomAdminCol={renderCustomAdminCol}
        renderCustomAdminSection={renderCustomAdminSection}
        modifyJStorageArgs={{
          modifyCreateDocumentArgs: (...args) => {
            let [collection, data] = args;
            if (collection === 'product') {
              return [
                collection,
                {
                  ...data,
                  store_id: user.store_id,
                },
              ];
            }
            return args;
          },
          modifyUpdateDocumentArgs: (...args) => {
            let [collection, query, data] = args;
            if (collection === 'site' && data.name === 'product_category') {
              const _translateCat = (data.categories || []).map((c) => ({
                ...c,
                name: c.display,
              }));

              let _updatedData = {
                ...data,
                categories: _translateCat,
              };
              return [collection, query, _updatedData];
            } else if (collection === 'product') {
              return [
                collection,
                query,
                {
                  ...data,
                  store_id: user.store_id,
                },
              ];
            }
            return args;
          },
          modifyFetchDocumentsArgs: (...args) => {
            const [collection, query, ...restArgs] = args;
            let nextQuery = query;
            if (
              collection === 'order' &&
              query.payment_subtype === '__others-except-offline'
            ) {
              nextQuery = {
                ...nextQuery,
                payment_subtype: {
                  $nin: [Cart.PAYMENT_SUBTYPE.offline],
                },
              };
            }

            if (
              collection === 'order' ||
              collection === 'product' ||
              collection === 'site' ||
              collection === 'billing'
            ) {
              nextQuery = {
                ...nextQuery,
                store_id: user.store_id,
              };
            }
            return [collection, nextQuery, ...restArgs];
          },
        }}
        {...props}
      />
    </>
  );
}

export default AdminResourcePage;
