import React from 'react';
import styled from 'styled-components';
import {Button, Modal, Input, message} from 'antd';
import * as AppActions from '../../AppActions';

const initialValues = {
  last_digits: '',
  account_name: '',
  tx_time: '',
};

function PayBillingButton(props) {
  const {billing, refresh} = props;
  const [modalVisibility, setModalVisibility] = React.useState(null);
  const [billingValues, setBillingValues] = React.useState({...initialValues});

  React.useEffect(() => {
    if (!modalVisibility) {
      setBillingValues({...initialValues});
    }
  }, [modalVisibility]);

  async function updateBillingRecord() {
    for (const key in billingValues) {
      if (!billingValues[key]) {
        alert('請填入必填欄位!');
        return;
      }
    }

    AppActions.setLoading(true);
    try {
      await AppActions.requestBillingVerify({
        id: billing.id,
        lastDigits: billingValues.last_digits,
        username: billingValues.account_name,
        payDate: billingValues.tx_time,
      });
      setModalVisibility(false);
    } catch (ex) {
      console.warn(ex);
      message.error('無法修改紀錄, 請稍後再嘗試');
    }
    AppActions.setLoading(false);

    refresh();
  }

  return (
    <>
      <Button
        onClick={() => {
          setModalVisibility(true);
        }}>
        確認付款
      </Button>

      <Modal
        title={'填寫付款資訊'}
        footer={null}
        visible={modalVisibility}
        closable={false}>
        {billing && (
          <ModalContent>
            <div>
              <div className="field">
                <label>繳費年度：</label>
                <span>{billing.year}</span>
              </div>
              <div className="field">
                <label>繳費月份：</label>
                <span>{('00' + billing.month).slice(-2)}</span>
              </div>
              <div className="field">
                <label>應繳金額：</label>
                <span>${billing.amount}</span>
              </div>
              <div className="field">
                <label>帳戶資訊：</label>
                <br />
                <div>國泰世華（013) 分行：中山分行</div>
                <div>想食數位股份有限公司 042-03-501194-8</div>
              </div>

              <div className="form">
                <div className="field">
                  <label>帳戶後五碼</label>
                  <Input
                    value={billingValues.last_digits}
                    onChange={(e) => {
                      setBillingValues({
                        ...billingValues,
                        last_digits: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="field">
                  <label>戶名</label>
                  <Input
                    value={billingValues.account_name}
                    onChange={(e) => {
                      setBillingValues({
                        ...billingValues,
                        account_name: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="field">
                  <label>匯款時間</label>
                  <Input
                    type="datetime-local"
                    value={billingValues.tx_time}
                    onChange={(e) => {
                      setBillingValues({
                        ...billingValues,
                        tx_time: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>

              <p>
                * 重要提醒:
                未付款項超過60天，系統管理員會提醒您並在7天內未收到款項將您的使用版本改為試用版，請留意並按時完成匯款。
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}>
              <Button onClick={() => setModalVisibility(false)}>取消</Button>
              <Button
                type="primary"
                style={{marginLeft: 10}}
                onClick={updateBillingRecord}>
                確認
              </Button>
            </div>
          </ModalContent>
        )}
      </Modal>
    </>
  );
}

const ModalContent = styled.div`
  & .field {
    & label {
      color: #888;
    }
    margin-bottom: 6px;
  }

  & p {
    margin-bottom: 20px;
    color: #aaa;
  }

  & .form {
    margin: 20px 0;
    & .field {
      position: relative;
      & label::after {
        content: '*';
        position: absolute;
        left: -7px;
        top: -3px;
        color: red;
      }
    }
  }
`;

export default PayBillingButton;
