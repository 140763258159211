import React from 'react';
import {Button} from 'antd';
import {getOutlet} from 'reconnect.js';
import * as AppActions from '../../AppActions';

function createTcatOrdersExcel(orders) {
  const wb = window.XLSX.utils.book_new();
  const fields = [
    '訂單編號',
    '出貨溫層',
    '規格 1-60cm, 2-90cm',
    '代收金額',
    '收件人',
    '收件人電話',
    '收件地址',
    '寄件人',
    '電話[寄]',
    '地址[寄]',
    '易碎 Y/N',
    '出貨日期',
    '配達日',
    '配達時間',
    '物品名稱',
    '備註',
    '平日或假日收件',
    '收件人備註',
    '管理員備註',
    '產品',
    '規格',
    '組合內容',
    '每組數量',
    '訂購組數',
    '數量',
  ];

  const wsOrders = window.XLSX.utils.aoa_to_sheet([
    [...fields],
    ...orders.map((o) => {
      return [
        o.order_id,
        1,
        1,
        0,
        o.receiver_name,
        o.receiver_phone,
        `${o.receiver_zip} ${o.receiver_city} ${o.receiver_district} ${o.receiver_address}`,
        '',
        '',
        '',
        'Y',
        '',
        '',
        '1',
        o.items.length === 1
          ? `${o.items[0].name}`
          : `${o.items[0].name}與其他商品`,
        '',
        '平日',
        o.order_note,
        '',
        '',
        '',
        '',
        '',
        '',
        '',
      ];
    }),
  ]);

  window.XLSX.utils.book_append_sheet(wb, wsOrders, '無檢貨單');

  const wbout = window.XLSX.write(wb, {
    bookType: 'xlsx',
    bookSST: false,
    type: 'array',
    cellStyles: true,
    bookImages: true,
  });

  return URL.createObjectURL(
    new Blob([wbout], {type: 'application/octet-stream'}),
  );
}

export default function ExportedTcatExcelDownloadButton(props) {
  const [downloadLink, setDownloadLink] = React.useState(null);

  return (
    <>
      <Button
        onClick={async () => {
          const orders = getOutlet('selected-orders').getValue();

          if (orders.length === 0) {
            alert('尚未選取訂單');
            return;
          }

          console.log('selected-orders', orders);

          AppActions.setLoading(true);
          try {
            await AppActions.delay(1000);
            setDownloadLink(createTcatOrdersExcel(orders));
          } catch (ex) {
            console.warn(ex);
          }
          AppActions.setLoading(false);
        }}>
        匯出TCAT訂單excel
      </Button>

      {downloadLink && (
        <Button
          type="link"
          href={downloadLink}
          download={'orders.xlsx'}
          target="_blank">
          下載TCAT訂單excel
        </Button>
      )}
    </>
  );
}
