import React from 'react';
import {useOutlet} from 'reconnect.js';
import styled from 'styled-components';
import {Button, Input, Checkbox, message} from 'antd';
import * as AppActions from '../../AppActions';
import * as Config from '../../../data.json';
import {Copy} from '@styled-icons/boxicons-regular/Copy';
import * as ClipboardUtil from '../../Utils/ClipboardUtil';

const initialValues = {
  channel_access_token: '',
  channel_secret: '',
  linepay_channel_id: '',
  linepay_channel_secret: '',
};

export default function LineIntegrationForm(props) {
  const [recordValues, setRecordValues] = React.useState(null);
  const [hintChecked, setHintChecked] = React.useState(false);
  const [user] = useOutlet('user');

  React.useEffect(() => {
    async function fetchData() {
      AppActions.setLoading(true);
      try {
        setRecordValues(await AppActions.fetchStorePrivateConfig());
      } catch (ex) {
        setRecordValues({...initialValues});
      }
      AppActions.setLoading(false);
    }

    fetchData();
  }, []);

  if (!recordValues) {
    return null;
  }

  async function updateRecord() {
    AppActions.setLoading(true);
    try {
      await AppActions.putStoreCredentials({...recordValues});
      message.success('成功更新');
    } catch (ex) {
      message.error('更新失敗');
      console.warn(ex);
    }
    AppActions.setLoading(false);
  }

  function getTextInputProps(key) {
    return {
      value: recordValues[key],
      onChange: (e) => {
        setRecordValues({
          ...recordValues,
          [key]: e.target.value,
        });
      },
    };
  }

  return (
    <Wrapper>
      <div>
        <div className="form">
          <div className="field">
            <label>CHANNEL ACCESS TOKEN</label>
            <Input {...getTextInputProps('channel_access_token')} />
          </div>

          <div className="field">
            <label>CHANNEL SECRET</label>
            <Input {...getTextInputProps('channel_secret')} />
          </div>

          <div className="field">
            <label>LINEPAY CHANNEL ID</label>
            <Input {...getTextInputProps('linepay_channel_id')} />
          </div>

          <div className="field">
            <label>LINEPAY CHANNEL SECRET</label>
            <Input {...getTextInputProps('linepay_channel_secret')} />
          </div>

          <div className="field">
            <label>LINE LOGIN CLIENT ID</label>
            <Input {...getTextInputProps('line_login_client_id')} />
          </div>

          <div className="field">
            <label>LINE LOGIN CLIENT SECRET</label>
            <Input {...getTextInputProps('line_login_client_secret')} />
          </div>
        </div>

        <div className="generated-links">
          <h2>LINE 圖文選單區</h2>
          <div className="item">
            <div>首頁</div>
            <div id="copy-store-url">{`${Config.webHost}/store/products/?store=${user.store_id}`}</div>
            <div onClick={() => ClipboardUtil.copyFromElem('copy-store-url')}>
              <Copy size={24} color="black" />
            </div>
          </div>
          <div className="item">
            <div>訂單列表頁面</div>
            <div id="copy-order-list-url">{`${Config.webHost}/store/profile/orders/?store=${user.store_id}`}</div>
            <div
              onClick={() => ClipboardUtil.copyFromElem('copy-order-list-url')}>
              <Copy size={24} color="black" />
            </div>
          </div>
          <div className="item">
            <div>登入頁面</div>
            <div id="copy-login-url">{`${Config.webHost}/store/social-login/?store=${user.store_id}`}</div>
            <div onClick={() => ClipboardUtil.copyFromElem('copy-login-url')}>
              <Copy size={24} color="black" />
            </div>
          </div>
        </div>
      </div>

      <div className="generated-links">
        <h2>LINE Login 設定區</h2>
        <div className="item">
          <div>Callback URL</div>
          <div id="copy-login-callback-url">{`${Config.apiHost}/store/line/callback`}</div>
          <div
            onClick={() =>
              ClipboardUtil.copyFromElem('copy-login-callback-url')
            }>
            <Copy size={24} color="black" />
          </div>
        </div>
      </div>

      <div className="check-box">
        <label>
          <span style={{color: 'red', display: 'inline-block'}}>*</span>
          為確保line@中購買的流程能夠順利使用，請店家填入圖文選單連結前，務必完成方案升級!
        </label>
        <div style={{marginTop: 6}}>
          <Checkbox
            value={hintChecked}
            onChange={(e) => setHintChecked(e.target.checked)}>
            我已了解
          </Checkbox>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}>
        <Button
          type="primary"
          style={{marginLeft: 10}}
          onClick={updateRecord}
          disabled={!hintChecked}>
          確認
        </Button>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  max-width: 800px;

  & .field {
    & label {
      color: #888;
    }
    margin-bottom: 15px;
  }

  & p {
    margin-bottom: 20px;
    color: #aaa;
  }

  & .form {
    margin: 20px 0;
    & .field {
      position: relative;
      & label::after {
        content: '*';
        position: absolute;
        left: -7px;
        top: -3px;
        color: red;
      }
    }
  }

  & .generated-links {
    border: 1px solid #aaa;
    border-radius: 20px;
    padding: 15px;
    background-color: #eee;
    margin-bottom: 20px;

    & > .item {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      & > * {
        margin: 5px;

        &:first-child {
          font-weight: 500;
        }

        &:last-child {
          border: 1px solid black;
          border-radius: 3px;
          cursor: pointer;
          box-shadow: 0 0 0 rgba(0, 0, 0, 0.5);
          transition: all 200ms;
          &:hover {
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
          }
        }
      }
    }
  }

    & .check-box {
      margin: 20px 0;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
`;
