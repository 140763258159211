import React from 'react';
import {Button, Popconfirm, message} from 'antd';
import * as Cart from 'rev.sdk.js/Actions/Cart';
import * as AppActions from '../../AppActions';

export function handleOrders({path, pageContext}) {
  if (path === '/admin/orders/waiting') {
    // note: use delete button to render extra actions
    pageContext.resource.renderDeleteButton = (instance, {refresh}) => {
      if (
        instance.payment_status === Cart.PAYMENT_STATUS.success ||
        instance.payment_subtype !== Cart.PAYMENT_SUBTYPE.offline
      ) {
        return null;
      }
      return (
        <Popconfirm
          cancelText={'取消'}
          okText={'確定'}
          title={`已經確認匯款後五碼？${
            instance.offline_tx ? '' : '注意：此筆訂單尚未填寫匯款資訊'
          }`}
          placement="topRight"
          onConfirm={async () => {
            const _hide = message.loading('更新付款狀態...');
            await AppActions.confirmOfflineOrder(instance.id);
            await refresh();
            _hide();
          }}>
          <Button type="primary">驗證</Button>
        </Popconfirm>
      );
    };
  }
}
