import React from 'react';
import {BILLING_STATUS} from '../../constants';
import PayBillingButton from './AdminPayBillingButton';

export function handleBilling({path, pageContext}) {
  if (path === '/admin/billing') {
    pageContext.resource.renderDetailButton = (billing, {refresh}) => {
      if (
        [BILLING_STATUS.pending, BILLING_STATUS.rejected].indexOf(
          billing.status,
        ) !== -1
      ) {
        return <PayBillingButton billing={billing} refresh={refresh} />;
      } else {
        return null;
      }
    };
  }
}
