import React from 'react';
import {Button} from 'antd';
import {getOutlet} from 'reconnect.js';
import * as AppActions from '../../AppActions';
import * as PdfRenderer from '@react-pdf/renderer';

PdfRenderer.Font.register({
  family: 'MiSans',
  src: '/fonts/MiSans-Normal.ttf',
});

const pagePadding = 20;

const styles = PdfRenderer.StyleSheet.create({
  page: {
    fontFamily: 'MiSans',
    flexDirection: 'column',
    backgroundColor: '#fff',
    padding: pagePadding,
  },
  section: {
    flexGrow: 1,
    alignSelf: 'stretch',
    borderWidth: 1,
    borderColor: '#ccc',
  },
  cell: {
    flex: 1,
    borderWidth: 1,
    color: 'black',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    fontSize: 12,
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
  },
});

function PdfFile(props) {
  const {generatedOrders} = props;
  const store_id = getOutlet('user').getValue().store_id;
  return (
    <PdfRenderer.Document>
      {generatedOrders.map((order, idx) => {
        let logiTypeText = '自取';
        if (order.logistics_type === 'HOME') {
          logiTypeText = '宅配';
        }

        return (
          <PdfRenderer.Page key={idx} size="A4" style={styles.page}>
            <PdfRenderer.View
              style={[styles.center, {backgroundColor: '#ccc'}]}>
              <PdfRenderer.Text style={styles.text}>
                {store_id}-訂單-{order.order_id}
              </PdfRenderer.Text>
              <PdfRenderer.Text style={styles.text}>
                {logiTypeText}
              </PdfRenderer.Text>
              {order.logistics_type === 'HOME' && (
                <PdfRenderer.Text style={styles.text}>
                  {`${order.receiver_zip} ${order.receiver_city} ${order.receiver_district} ${order.receiver_address}`}
                </PdfRenderer.Text>
              )}
            </PdfRenderer.View>

            <PdfRenderer.View style={{display: 'flex', flexDirection: 'row'}}>
              <PdfRenderer.View style={[styles.cell]}>
                <PdfRenderer.Text style={[styles.text]}>姓名</PdfRenderer.Text>
              </PdfRenderer.View>
              <PdfRenderer.View style={[styles.cell]}>
                <PdfRenderer.Text style={[styles.text]}>手機</PdfRenderer.Text>
              </PdfRenderer.View>
            </PdfRenderer.View>

            <PdfRenderer.View style={{display: 'flex', flexDirection: 'row'}}>
              <PdfRenderer.View style={[styles.cell]}>
                <PdfRenderer.Text style={[styles.text]}>
                  {order.receiver_name}
                </PdfRenderer.Text>
              </PdfRenderer.View>
              <PdfRenderer.View style={[styles.cell]}>
                <PdfRenderer.Text style={[styles.text]}>
                  {order.receiver_phone}
                </PdfRenderer.Text>
              </PdfRenderer.View>
            </PdfRenderer.View>

            <PdfRenderer.View
              style={[styles.center, {backgroundColor: '#ccc'}]}>
              <PdfRenderer.Text style={styles.text}>所有品項</PdfRenderer.Text>
            </PdfRenderer.View>

            <PdfRenderer.View style={{display: 'flex', flexDirection: 'row'}}>
              <PdfRenderer.View style={[styles.cell]}>
                <PdfRenderer.Text style={[styles.text]}>名稱</PdfRenderer.Text>
              </PdfRenderer.View>
              <PdfRenderer.View style={[styles.cell, {flex: 2}]}>
                <PdfRenderer.Text style={[styles.text]}>規格</PdfRenderer.Text>
              </PdfRenderer.View>
              <PdfRenderer.View style={[styles.cell]}>
                <PdfRenderer.Text style={[styles.text]}>小計</PdfRenderer.Text>
              </PdfRenderer.View>
            </PdfRenderer.View>

            {order.items.map((i, index) => (
              <PdfRenderer.View
                key={index}
                style={{display: 'flex', flexDirection: 'row'}}>
                <PdfRenderer.View style={[styles.cell]}>
                  <PdfRenderer.Text style={[styles.text]}>
                    {i.name}
                  </PdfRenderer.Text>
                </PdfRenderer.View>
                <PdfRenderer.View style={[styles.cell, {flex: 2}]}>
                  <PdfRenderer.Text style={[styles.text]}>
                    數量：{i.config.qty}
                  </PdfRenderer.Text>
                  {i.config.variants.map(({name, choice}, idx) => (
                    <PdfRenderer.Text key={idx} style={[styles.text]}>
                      {name} {choice ? `:${choice}` : ''}
                    </PdfRenderer.Text>
                  ))}
                </PdfRenderer.View>
                <PdfRenderer.View style={[styles.cell]}>
                  <PdfRenderer.Text style={[styles.text]}>
                    {i.amount}
                  </PdfRenderer.Text>
                </PdfRenderer.View>
              </PdfRenderer.View>
            ))}

            <PdfRenderer.View
              style={[styles.center, {backgroundColor: '#ccc'}]}>
              <PdfRenderer.Text style={styles.text}>金額</PdfRenderer.Text>
            </PdfRenderer.View>

            <PdfRenderer.View style={{display: 'flex', flexDirection: 'row'}}>
              <PdfRenderer.View style={[styles.cell]}>
                <PdfRenderer.Text style={[styles.text]}>商品</PdfRenderer.Text>
              </PdfRenderer.View>
              <PdfRenderer.View style={[styles.cell]}>
                <PdfRenderer.Text style={[styles.text]}>
                  運費及其他
                </PdfRenderer.Text>
              </PdfRenderer.View>
              <PdfRenderer.View style={[styles.cell]}>
                <PdfRenderer.Text style={[styles.text]}>總計</PdfRenderer.Text>
              </PdfRenderer.View>
            </PdfRenderer.View>

            <PdfRenderer.View style={{display: 'flex', flexDirection: 'row'}}>
              <PdfRenderer.View style={[styles.cell]}>
                <PdfRenderer.Text style={[styles.text]}>
                  {order.subtotal}
                </PdfRenderer.Text>
              </PdfRenderer.View>
              <PdfRenderer.View style={[styles.cell]}>
                <PdfRenderer.Text style={[styles.text]}>
                  {order.fee}
                </PdfRenderer.Text>
              </PdfRenderer.View>
              <PdfRenderer.View style={[styles.cell]}>
                <PdfRenderer.Text style={[styles.text]}>
                  {order.total}
                </PdfRenderer.Text>
              </PdfRenderer.View>
            </PdfRenderer.View>
          </PdfRenderer.Page>
        );
      })}
    </PdfRenderer.Document>
  );
}

export default function ExportedOrderPdfDownloadButton(props) {
  const [generatedOrders, setGeneratedOrders] = React.useState([]);
  return (
    <>
      <Button
        onClick={async () => {
          const orders = getOutlet('selected-orders').getValue();

          if (orders.length === 0) {
            alert('尚未選取訂單');
            return;
          }

          console.log('selected-orders', orders);

          AppActions.setLoading(true);
          try {
            await AppActions.delay(1000);
            setGeneratedOrders(orders);
          } catch (ex) {
            console.warn(ex);
          }
          AppActions.setLoading(false);
        }}>
        匯出訂單PDF
      </Button>

      {generatedOrders.length > 0 && (
        <PdfRenderer.BlobProvider
          document={<PdfFile generatedOrders={generatedOrders} />}>
          {({...rest}) => {
            if (rest.url) {
              return (
                <a
                  href="/#"
                  style={{margin: 10}}
                  onClick={(evt) => {
                    evt.preventDefault();
                    window.open(rest.url, '_blank');
                  }}>
                  下載訂單PDF
                </a>
              );
            } else {
              return (
                <a href="/#" style={{margin: 10}}>
                  Generating...
                </a>
              );
            }
          }}
        </PdfRenderer.BlobProvider>
      )}
    </>
  );
}
