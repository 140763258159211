import React from 'react';
import {getOutlet} from 'reconnect.js';
import ExportedOrderPdfDownloadButton from './ExportedOrderPdfDownloadButton';
import ExportedTcatExcelDownloadButton from './ExportedTcatExcelDownloadButton';

export function handleOrdersExport({path, pageContext}) {
  if (path.indexOf('/admin/orders/export') > -1) {
    pageContext.resource = {
      ...pageContext.resource,
      resourceTableProps: {
        scroll: {
          x: 1300,
        },
        rowSelection: {
          onChange: (_, selectedRows) => {
            getOutlet('selected-orders')?.update([...selectedRows]);
          },
        },
      },
      renderCreateButton: () => {
        return (
          <div style={{display: 'flex', alignItems: 'center'}}>
            <ExportedOrderPdfDownloadButton />
            <div style={{width: 10}} />
            <ExportedTcatExcelDownloadButton />
          </div>
        );
      },
    };
  }
}
