import React from 'react';

function renderCustomCartCol(props) {
  const {name, data} = props;
  if (name === 'CART_CUSTOM_COLUMN') {
    return data.item.config.extra_data?.stock_chosen_date ? (
      <div style={{whiteSpace: 'nowrap'}}>
        訂購日期:
        <br />
        {data.item.config.extra_data.stock_chosen_date}
      </div>
    ) : null;
  }
  return null;
}

export default renderCustomCartCol;
